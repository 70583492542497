<template>
  <Stack>
    <b-tabs pills>
      <b-tab active>
        <template #title>
          <feather-icon
            icon="MapPinIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">{{ $t('fee.domesticTicket') }}</span>
        </template>
        <FeeTab
          ticket-type="INLAND"
          :fee-configs-data="feeConfigsData.inland"
          :agency-id="agencyId"
          @refetch-data="getFeeConfigsData"
        />
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon
            icon="NavigationIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">{{ $t('fee.internationalTicket') }}</span>
        </template>
        <FeeTab
          ticket-type="INTERNATIONAL"
          :fee-configs-data="feeConfigsData.international"
          :agency-id="agencyId"
          @refetch-data="getFeeConfigsData"
        />
      </b-tab>
    </b-tabs>
    <IAmOverlay
      v-if="canModifyF1AgencyFee"
      :loading="loadingF1AgencyFee"
    >
      <BCard>
        <BFormCheckbox
          class="custom-control-success cursor-pointer"
          name="check-button"
          inline
          switch
          :checked="getAgencyData?.hideFeeAllChild"
          @click.native.prevent="onChangeF1AgencyFeeHandle"
        >
          <div class="fw-800 text-16px cursor-pointer">
            {{ $t(`fee.f1AgencyFee`) }}
          </div>
        </BFormCheckbox>
      </BCard>
    </IAmOverlay>
  </Stack>
</template>

<script>
import {
  BTab, BTabs, BCard, BFormCheckbox,
} from 'bootstrap-vue'
import { onMounted, ref, computed } from '@vue/composition-api'
import { v4 as uuidv4 } from 'uuid'

import { apiAgencies } from '@/api'
import { getUserData } from '@/api/auth/utils'
import store from '@/store'
import env from '@/libs/env'

import useToast from '@useToast'

import FeeTab from './FeeTab.vue'

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BFormCheckbox,
    FeeTab,
    IAmOverlay: () => import('@/components/IAmOverlay.vue'),
  },
  setup(_, { root }) {
    const feeConfigsData = ref({ inland: [], international: [] })
    const agencyId = ref()
    const distributorsList = computed(() => store.getters['globalConfig/getDistributors'])

    async function getFeeConfigsData() {
      root.$bvModal.show('modal-api-loading')
      if (!agencyId.value) return
      const { data } = await apiAgencies.getFeeConfigs(agencyId.value)

      // Fake những source chưa có cấu hình phí xuất vé
      Object.keys(distributorsList.value).forEach(key => {
        distributorsList.value[key].forEach(sourceItem => {
          if (data.find(i => i.ticketType === key.toUpperCase() && i.airline === sourceItem)) return
          data.push({
            id: uuidv4(),
            airline: sourceItem,
            feeType: 'FLIGHT_ITINERARY',
            amount: 0,
            ticketType: key.toUpperCase(),
          })
        })
      })

      feeConfigsData.value = {
        inland: data.filter(fitem => fitem.ticketType === 'INLAND'),
        international: data.filter(fitem => fitem.ticketType === 'INTERNATIONAL'),
      }
      root.$bvModal.hide('modal-api-loading')
    }

    onMounted(() => {
      store.dispatch('globalConfig/getAvailableDistributorSource')
      agencyId.value = getUserData().employeeData?.agency?.id
      if (!agencyId.value) {
        return
      }
      getFeeConfigsData()
    })

    const { toastSuccess, toastError } = useToast()

    const getAgencyData = computed(() => store.getters['userStore/getAgencyData'])
    const getMeDataType = computed(() => store.getters['userStore/getMeDataType'])
    const isRoleF1 = computed(() => store.getters['userStore/getRoleMama'])
    const loadingF1AgencyFee = ref(false)
    function onChangeF1AgencyFeeHandle() {
      const titleMsg = () => this.$createElement('div', { domProps: { innerHTML: this.$t('fee.f1AgencyFeeConfirm') } })
      this.$bvModal
        .msgBoxConfirm(titleMsg(), {
          title: this.$t('modal.confirm'),
          size: 'md',
          okVariant: 'info',
          okTitle: this.$t('modal.iamConfirm'),
          cancelTitle: this.$t('modal.close'),
          cancelVariant: 'outline-danger',
          hideHeaderClose: true,
          centered: true,
        })
        .then(async value => {
          if (value) {
            try {
              loadingF1AgencyFee.value = true
              await apiAgencies.changeHideFeeAllChild()
              toastSuccess('fee.f1AgencyFeeSuccess')
            } catch (error) {
              toastError('fee.f1AgencyFeeError')
            } finally {
              await store.dispatch('userStore/fetchMeData')
              loadingF1AgencyFee.value = false
            }
          }
        })
    }

    // tắt bật tính năng ẩn phí cho cấp dưới, api này chỉ có ADM, OPE của F1 được dùng,
    // https://discord.com/channels/1054696448110903327/1336550926655295549
    const canModifyF1AgencyFee = computed(() => isRoleF1.value && ['ADM', 'OPE'].includes(getMeDataType.value) && !env.isHideF1AgencyFee)
    return {
      feeConfigsData,
      getFeeConfigsData,
      agencyId,
      getAgencyData,
      onChangeF1AgencyFeeHandle,
      loadingF1AgencyFee,
      canModifyF1AgencyFee,
    }
  },
}
</script>
